html { -webkit-tap-highlight-color: rgba(0,0,0,0); }

.program{
  padding: 100px 0;
  transition: all .5s ease;
  ul{
    overflow: hidden;
    transition: all .5s ease;
  }
  .accordion-item{
    border-bottom: 1px solid #C5A45A;
    margin-top: 0!important;
    padding: 20px 0;
    opacity: 0;
    list-style-type: none;
    &:first-child{
      border-top: 1px solid #C5A45A;
    }
    &:hover{
      .program-title{
        color: #C5A45A;
      }
    }
  }

  .accent_head{
    font-size: 80px;
  }
  .uk-accordion-title{
    font-size: 40px;
    font-family: calps-bold;
    color: #646363;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    &:after{
      display: none;
    }
    &:before{
      position: absolute;
      right: 0;
    }
    .program-date{
      width: 7%;
      display: inline-block;
      text-align: center;
      span{
        display: block;
        width: 100%;
        &:first-child{
          font-size: 17px;
          font-family: calps-light;
          text-transform: uppercase;
          color: #C5A45A;
        }
        &:last-child{
          font-size: 40px;
          font-family: calps-light;
          color: #646363;
          line-height: 30px;
        }
      }
    }
    .program-title{
      width: 93%;
      display: inline-block;
      font-family: calps-bold;
      color: #646363;
      margin-top: 5px;
      font-size: 40px;
      margin-bottom: 0;
      padding-right: 40px;
      padding-left: 20px;
      transition: all .5s ease;
    }
  }
  .uk-accordion-content{
    display: flex;
    justify-content: flex-start;
    margin-top: 0;
    a {
      color: #C5A45A;
      &:after{
        height: 1px;
      }
    }
    >div{
      &:first-child{
        width: 7%;
      }
      &:last-child{
        width: 93%;
        padding-left: 20px;
      }
    }
  }
  .accent_head{
    margin-bottom: 40px;
  }
}

.accent_head {
  font-family: calps-bold, sans-serif;
  font-size: 100px;
  color: #C5A45A;
  opacity: .5;
  position: relative;
  line-height: 90px;
}

.section_top{
  img{
    object-fit: cover;
    height: 100%;
  }
  .content_wrap{
    padding: 50px 60px;
  }
  .accent_head{
    margin-bottom: 40px;
  }
}

.section_4 {
  padding: 100px 0;
  .content_img{
    z-index: 0;
    height: 100%;
  }
  img{
    object-fit: cover;
    height: 100%;
  }
  .content_wrap{
    padding: 50px 60px;
    .accent_head {
      font-size: 60px;
      line-height: 50px;
    }
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
    &:not(:last-of-type){
      margin-bottom: 20px;
    }
  }
}

.button-more{
  background-color: #C5A45A;
  border: none;
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-family: calps-light, sans-serif;
  cursor: pointer;
  width: 250px;
  height: 50px;
  margin-top: 60px;
  transition: all .5s ease;
  &:hover{
    background-color: darken(#C5A45A, 10%);
  }
  &:focus, &:active {
    outline: none;
  }
}


.copyright{
  color: rgba(white, .8);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 75px;
  a{
    &:after{
      display: none;
    }
  }
  img{
    height: 14px;
    margin-top: -4px;
    margin-left: 3px;
  }
}

footer{
  padding-bottom: 75px;
}


.instagram{
  padding-top: 100px;
  padding-bottom: 200px;
  .instagram-wrap-item{
    padding-top: 100%;
    position: relative;
    display: block;
    overflow: hidden;
    &:after{
      display: none;
    }
    &:hover{
      img{
       transform: scale(1.1);
      }
    }
    .instagram-icon{
      position: absolute;
      top: 20px;
      z-index: 100;
      right: 20px;
      background-image: url(../assets/img/instagram.svg);
      background-size: 100% 100%;
      display: block;
      width: 26px;
      height: 26px;
    }
    img{
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all .5s ease;
    }
  }
}


@media (max-width: 960px) {
  .accent_head{
    font-size: 40px;
  }
}


@media (max-width: 640px) {
  .program .accent_head{
    font-size: 40px;
  }

  .section_4{
    padding: 50px 0;
    .uk-container{
      > .uk-grid:nth-child(even){
        flex-direction: column-reverse;
      }
    }
  }
  .program .uk-accordion-title .program-title{
    width: 85%;
    font-size: 26px;
  }
  .program .uk-accordion-title .program-date{
    width: 15%;
  }
  .program .uk-accordion-content{
    margin-bottom: 0;
    >div{
      &:first-child{
        width: 15%;
      }
      &:last-child{
        width: 85%;
        padding-left: 17px;
      }
    }
  }
  .section_top .content_wrap{
    padding: 20px;
    .accent_head{
      margin-bottom: 0;
    }
    h1.accent_head{
      margin-bottom: 20px;
    }
  }
  .accent_head{
    line-height: 50px;
  }
  .section_4 .content_wrap{
    padding: 20px;
    h3.accent_head{
      font-size: 40px;
    }
  }
  footer{
    a{
      font-size: 18px;
    }
  }
}
